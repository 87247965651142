import navigate from '~/mixins/navigate'
import Library from './Library'

export default {
  mixins: [navigate],

  props: {
    store: {
      type: String,
      required: true,
      validator: prop => ['apple', 'google'].includes(prop),
    },

    locale: {
      type: String,
      required: true,
      validator: prop => ['pt-br', 'es', 'es-es', 'es-mx', 'es-co', 'en'].includes(prop),
    },
  },

  computed: {
    storeClasses() {
      return `app-store-button--${this.store}`
    },

    image() {
      const locale = this.locale.replace('-', '')

      return Library[this.store][locale]
    },
  },
}
